<template>
  <router-view />
  <v-idle @idle="onidle" :loop="true" :wait="10" :duration="1800" />
  <!-- <v-idle
    @idle="onidle"
    @remind="onremind"
    :loop="true"
    :reminders="[20, 30]"
    :wait="60"
    :duration="300"
  /> -->
</template>
<script>
import { watch } from 'vue'
import { useStore } from 'vuex'
//import moment from 'moment'

export default {
  setup() {
    const store = useStore()
    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  },
  methods: {
    onidle() {
      if (localStorage.getItem('user')) {
        //alert('Vous avez été déconnecté')
        localStorage.clear()
        this.$router.push('/login')
      }
    },
    onremind(time) {
      // alert seconds remaining to 00:00
      console.log(time)
      alert(time)
    },
    checkStatus() {
      if (localStorage.getItem('_timestamp')) {
        const date1 = new Date()
        const date2 = new Date(localStorage.getItem('_timestamp'))
        /* console.log(moment(date2).format('MMMM/YYYY'))
        console.log(date2.getTime()) */
        let subs = date1.getTime() - date2.getTime()
        //console.log(subs)
        if (subs > 1000 * 60 * 30) {
          localStorage.clear()
          this.$router.push('/login')
        }
      } else {
        localStorage.clear()
        this.$router.push('/login')
      }
    },
  },
  mounted() {
    this.checkStatus()
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
