import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { authGuard } from '@/_helpers/auth-guard'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: '/unauthorized',
        name: 'Non autorisé',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () =>
        //  import(/* webpackChunkName: "dashboard" */ '@/views/Tableau.vue'),
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '/home',
        name: 'Tableau de bord',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () =>
        //  import(/* webpackChunkName: "dashboard" */ '@/views/Tableau.vue'),
        component: () => import('@/views/Tableau.vue'),
      },
      {
        path: '/profil',
        name: 'Profil',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/profil/liste',
        children: [
          {
            path: '/profil/liste',
            name: 'Liste de profils',
            component: () => import('@/views/profil/ProfilList.vue'),
          },
          {
            path: '/profil/nouveau',
            name: 'Nouveau profil',
            component: () => import('@/views/profil/ProfilNew.vue'),
          },
          {
            path: '/profil/edition',
            name: 'Modification profil',
            component: () => import('@/views/profil/ProfilNew.vue'),
          },
          {
            path: '/profil/details',
            name: 'Visualisation de profil',
            component: () => import('@/views/profil/ProfilDetails.vue'),
          },
        ],
      },
      {
        path: '/utilisateur',
        name: 'Utilisateur',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/utilisateur/liste',
        children: [
          {
            path: '/utilisateur/liste',
            name: "Liste d'Utilisateurs",
            component: () => import('@/views/utilisateur/UtilisateurList.vue'),
          },
          {
            path: '/utilisateur/nouveau',
            name: 'Nouvel Utilisateur',
            component: () => import('@/views/utilisateur/UtilisateurNew.vue'),
          },
          {
            path: '/utilisateur/edition',
            name: 'Modifier un utilisateur',
            component: () => import('@/views/utilisateur/UtilisateurNew.vue'),
          },
          {
            path: '/utilisateur/details',
            name: "Visualisation de l'utilisateur",
            component: () =>
              import('@/views/utilisateur/UtilisateurDetails.vue'),
          },
          {
            path: '/utilisateur/profil',
            name: 'Mon profil',
            component: () =>
              import('@/views/utilisateur/UtilisateurProfil.vue'),
          },
        ],
      },
      {
        path: '/acteur',
        name: 'Acteur',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/acteur/liste',
        children: [
          {
            path: '/acteur/liste',
            name: "Liste d'Acteurs",
            component: () => import('@/views/acteur/ActeurList.vue'),
          },
          {
            path: '/acteur/nouveau',
            name: 'Nouvel Acteur',
            component: () => import('@/views/acteur/ActeurNew.vue'),
          },
          {
            path: '/acteur/edition',
            name: 'Modifier un Acteur',
            component: () => import('@/views/acteur/ActeurNew.vue'),
          },
          {
            path: '/acteur/details',
            name: "Visualisation de l'acteur",
            component: () => import('@/views/acteur/ActeurDetails.vue'),
          },
        ],
      },
      {
        path: '/localite',
        name: 'Localite',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/localite/liste',
        children: [
          {
            path: '/localite/liste',
            name: 'Liste de Localités',
            component: () => import('@/views/localite/LocaliteList.vue'),
          },
          {
            path: '/localite/nouveau',
            name: 'Nouveau localité',
            component: () => import('@/views/localite/LocaliteNew.vue'),
          },
          {
            path: '/localite/edition',
            name: 'Modifier une localité',
            component: () => import('@/views/localite/LocaliteNew.vue'),
          },
          {
            path: '/localite/details',
            name: 'Visualisation de la localité',
            component: () => import('@/views/localite/LocaliteDetails.vue'),
          },
        ],
      },
      {
        path: '/cadre',
        name: 'Programme/Projet',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/cadre/liste',
        children: [
          {
            path: '/cadre/liste',
            name: 'Liste des Programmes/Projets',
            component: () => import('@/views/cadre/CadreList.vue'),
          },
          {
            path: '/cadre/nouveau',
            name: 'Nouveau Programme/Projet',
            component: () => import('@/views/cadre/CadreNew.vue'),
          },
          {
            path: '/cadre/edition',
            name: 'Modifier ce Programme/Projet',
            component: () => import('@/views/cadre/CadreNew.vue'),
          },
          {
            path: '/cadre/details',
            name: 'Visualisation du Programme/Projet',
            component: () => import('@/views/cadre/CadreDetails.vue'),
          },
        ],
      },
      {
        path: '/ouvrage',
        name: 'Ouvrages',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/ouvrage/liste',
        children: [
          {
            path: '/ouvrage/liste',
            name: "Liste d'Ouvrages",
            component: () => import('@/views/ouvrage/OuvrageList.vue'),
          },
          {
            path: '/ouvrage/nouveau',
            name: 'Nouvel Ouvrage',
            component: () => import('@/views/ouvrage/OuvrageNew.vue'),
          },
          {
            path: '/ouvrage/edition',
            name: 'Modifier un Ouvrage',
            component: () => import('@/views/ouvrage/OuvrageNew.vue'),
          },
          {
            path: '/ouvrage/details',
            name: "Visualisation de l'Ouvrage",
            component: () => import('@/views/ouvrage/OuvrageDetails.vue'),
          },
        ],
      },
      {
        path: '/contrat',
        name: 'Contrat',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/contrat/liste',
        children: [
          {
            path: '/contrat/liste',
            name: 'Liste de Contrats',
            component: () => import('@/views/contrat/ContratList.vue'),
          },
          {
            path: '/contrat/nouveau',
            name: 'Nouveau contrat',
            component: () => import('@/views/contrat/ContratNew.vue'),
          },
          {
            path: '/contrat/edition',
            name: 'Modifier un contrat',
            component: () => import('@/views/contrat/ContratNew.vue'),
          },
          {
            path: '/contrat/details',
            name: 'Visualisation du contrat',
            component: () => import('@/views/contrat/ContratDetails.vue'),
          },
        ],
      },
      {
        path: '/redevance',
        name: 'Redevance',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/redevance/liste',
        children: [
          {
            path: '/redevance/liste',
            name: 'Liste de paiement de Redevances',
            component: () => import('@/views/redevance/RedevanceList.vue'),
          },
          {
            path: '/redevance/nouveau',
            name: 'Paiement de redevance',
            component: () => import('@/views/redevance/RedevanceNew.vue'),
          },
          {
            path: '/redevance/edition',
            name: 'Modifier une redevance',
            component: () => import('@/views/redevance/RedevanceNew.vue'),
          },
          {
            path: '/redevance/details',
            name: 'Détail du paiement de redevance',
            component: () => import('@/views/redevance/RedevanceDetails.vue'),
          },
        ],
      },
      {
        path: '/panne',
        name: 'Panne',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/panne/liste',
        children: [
          {
            path: '/panne/liste',
            name: 'Liste de Pannes',
            component: () => import('@/views/panne/PanneList.vue'),
          },
          {
            path: '/panne/nouveau',
            name: 'Nouvelle Panne',
            component: () => import('@/views/panne/PanneNew.vue'),
          },
          {
            path: '/panne/edition',
            name: 'Modifier une Panne',
            component: () => import('@/views/panne/PanneNew.vue'),
          },
          {
            path: '/panne/details',
            name: 'Visualisation de la Panne',
            component: () => import('@/views/panne/PanneDetails.vue'),
          },
        ],
      },
      {
        path: '/maintenance',
        name: 'Maintenance',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/maintenance/liste',
        children: [
          {
            path: '/maintenance/liste',
            name: 'Liste de Maintenances',
            component: () => import('@/views/maintenance/MaintenanceList.vue'),
          },
          {
            path: '/maintenance/nouveau',
            name: 'Nouvelle Maintenance',
            component: () => import('@/views/maintenance/MaintenanceNew.vue'),
          },
          {
            path: '/maintenance/edition',
            name: 'Modifier une Maintenance',
            component: () => import('@/views/maintenance/MaintenanceNew.vue'),
          },
          {
            path: '/maintenance/details',
            name: 'Visualisation de la Maintenance',
            component: () =>
              import('@/views/maintenance/MaintenanceDetails.vue'),
          },
        ],
      },
      {
        path: '/intervention',
        name: 'Intervention',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/intervention/liste',
        children: [
          {
            path: '/intervention/liste',
            name: 'Liste des Interventions',
            component: () =>
              import('@/views/intervention/InterventionList.vue'),
          },
          {
            path: '/intervention/nouveau',
            name: 'Nouvelle Intervention',
            component: () => import('@/views/intervention/InterventionNew.vue'),
          },
          {
            path: '/intervention/edition',
            name: 'Modifier une Intervention',
            component: () => import('@/views/intervention/InterventionNew.vue'),
          },
          {
            path: '/intervention/details',
            name: "Visualisation de l'intervention",
            component: () =>
              import('@/views/intervention/InterventionDetails.vue'),
          },
        ],
      },
      /* {
        path: '/situation',
        name: 'Situation',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/situation/impayes',
        children: [
          {
            path: '/situation/impayes',
            name: 'Liste des impayés',
            component: () => import('@/views/situation/Impayes.vue'),
          },
        ],
      }, */
      {
        path: '/piece',
        name: 'Piece',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/piece/liste',
        children: [
          {
            path: '/piece/liste',
            name: 'Liste des pièces',
            component: () => import('@/views/piece/PieceList.vue'),
          },
          {
            path: '/piece/nouveau',
            name: 'Nouvelle piece',
            component: () => import('@/views/piece/PieceNew.vue'),
          },
          {
            path: '/piece/edition',
            name: 'Modifier une Piece',
            component: () => import('@/views/piece/PieceNew.vue'),
          },
          {
            path: '/piece/details',
            name: 'Visualisation de la pièce',
            component: () => import('@/views/piece/PieceDetails.vue'),
          },
        ],
      },
      {
        path: '/rapport-ouvrages',
        name: 'Rapport Ouvrages enregistrés',
        component: () => import('@/views/rapport/RapportOuvrages.vue'),
      },
      {
        path: '/rapport-pannes',
        name: 'Rapport des Pannes',
        component: () => import('@/views/rapport/RapportPannes.vue'),
      },
      {
        path: '/rapport-maintenances',
        name: 'Rapport des maintenances',
        component: () => import('@/views/rapport/RapportMaintenances.vue'),
      },
      {
        path: '/rapport-interventions',
        name: 'Rapport Interventions',
        component: () => import('@/views/rapport/RapportInterventions.vue'),
      },
      {
        path: '/rapport-redevances',
        name: 'Rapport paiement de redevance',
        component: () => import('@/views/rapport/RapportRedevances.vue'),
      },
      {
        path: '/rapport-impayes',
        name: 'Rapport impayés sur redevance',
        component: () => import('@/views/rapport/RapportImpayes.vue'),
      },
      /* {
        path: '/rapport-evenements',
        name: 'Logs utilisateurs',
        component: () => import('@/views/rapport/RapportEvenements.vue'),
      }, */
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched[0].name == 'Accueil') {
    authGuard()
  }
  next()
})
export default router
