import axios from 'axios'

export default axios.create({
  baseURL: process.env.VUE_APP_BE_URL || 'https://aep-be.parakou.bj/api',
  //baseURL: 'http://192.168.2.26:85/api',
  //baseURL: 'http://localhost/sig-aep-be/public/api',
  headers: {
    'Content-type': 'application/json',
    'Content-Type': 'multipart/form-data',
  },
})
