const navItems = [
  {
    component: 'CNavItem',
    name: 'Tableau de bord',
    to: '/home',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: 'Gestion AEP',
  },
  {
    component: 'CNavGroup',
    name: 'Programme/Projet',
    to: '/cadre',
    icon: 'cil-paperclip',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/cadre/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/cadre/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Ouvrage AEP',
    to: '/ouvrage',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/ouvrage/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/ouvrage/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Contrat',
    to: '/contrat',
    icon: 'cil-pen-alt',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/contrat/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/contrat/nouveau',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Suivi AEP',
  },
  {
    component: 'CNavGroup',
    name: 'Redevance',
    to: '/redevance',
    icon: 'cil-wallet',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/redevance/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/redevance/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Panne',
    to: '/panne',
    icon: 'cil-bell-exclamation',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/panne/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/panne/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Maintenance',
    to: '/maintenance',
    icon: 'cil-calendar',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/maintenance/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/maintenance/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Intervention',
    to: '/intervention',
    icon: 'cil-settings',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/intervention/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/intervention/nouveau',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Administration',
  },
  {
    component: 'CNavGroup',
    name: 'Profil',
    to: '/profil',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/profil/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/profil/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Utilisateur',
    to: '/utilisateur',
    icon: 'cil-user',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/utilisateur/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/utilisateur/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Acteur',
    to: '/acteur',
    icon: 'cil-user-follow',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/acteur/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/acteur/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Localité',
    to: '/localite',
    icon: 'cil-location-pin',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/localite/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/localite/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Pièce',
    to: '/piece',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/piece/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/piece/nouveau',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Rapports',
  },
  {
    component: 'CNavItem',
    name: 'Ouvrages enregistrés',
    to: '/rapport-ouvrages',
    icon: 'cil-clipboard',
  },
  {
    component: 'CNavItem',
    name: 'Pannes survenues',
    to: '/rapport-pannes',
    icon: 'cil-note-add',
  },
  {
    component: 'CNavItem',
    name: 'Maintenances planifiée',
    to: '/rapport-maintenances',
    icon: 'cil-pin',
  },
  {
    component: 'CNavItem',
    name: 'Interventions',
    to: '/rapport-interventions',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Redevances',
    to: '/rapport-redevances',
    icon: 'cil-cash',
  },
  {
    component: 'CNavItem',
    name: 'Impayés',
    to: '/rapport-impayes',
    icon: 'cil-cash',
  },
]

export default navItems
