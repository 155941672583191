const myPlugin = {
  install(app) {
    ;(app.config.globalProperties.$authCheck = (key) => {
      let res = false
      let autorizations = JSON.parse(localStorage.getItem('user'))?.auths
      //console.log(autorizations)
      localStorage.setItem('_timestamp', new Date())
      if (autorizations !== undefined /* && autorizations.lenght > 0 */) {
        /* test = autorizations.find((element) => element.id_droit == droit)
      console.log(test)
      if (test !== undefined) return true
      else return false */
        autorizations.forEach((element) => {
          //if (element.id_droit == key)
          if (element.id_droit == key) {
            res = true
            console.log(res)
            return true
          }
        })
      }
      return res
    }),
      (app.config.globalProperties.$isLoggedIn = () => {
        return localStorage.getItem('user') ? true : false
      }),
      (app.config.globalProperties.$formatNum = (a, b) => {
        a = '' + a
        b = b || ' '
        var c = '',
          d = 0
        while (a.match(/^0[0-9]/)) {
          a = a.substr(1)
        }
        for (var i = a.length - 1; i >= 0; i--) {
          c = d != 0 && d % 3 == 0 ? a[i] + b + c : a[i] + c
          d++
        }
        return c
      })
  },
}

export default myPlugin
