<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <!-- <CIcon :icon="logo" height="48" alt="Logo" /> -->
        <!-- <CIcon :src="logocustom" height="48" alt="Logo" /> -->
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="#"> Tableau de bord </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav class="ms-3 me-4">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
//import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
//import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
//import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { logo } from '@/assets/brand/logo'
import logocustom from '@/assets/images/logosig.png'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    //AppHeaderDropdownMssgs,
    //AppHeaderDropdownNotif,
    //AppHeaderDropdownTasks,
  },
  setup() {
    return {
      logo,
      logocustom,
    }
  },
}
</script>
