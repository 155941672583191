import httpCommon from '@/http-common'

let login = (identifiants) => {
  return httpCommon.post('/users/signin', identifiants)
}

let logout = () => {
  localStorage.clear()
}

let saveToken = (hash) => {
  localStorage.setItem('hash', hash)
  localStorage.setItem('_timestamp', new Date())
}

let getToken = () => {
  localStorage.getItem('hash')
}

let isLogged = () => {
  let hash = localStorage.getItem('hash')
  return !!hash
}

export const AccountService = {
  login,
  logout,
  saveToken,
  getToken,
  isLogged,
}
